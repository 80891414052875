import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import FlechePrecedent from '../assets/Pictogrammes/picto-fleche-1.svg';
import FlecheSuivant from '../assets/Pictogrammes/picto-fleche-2.svg';
import Slider from 'react-slick';
import FlecheRetour from '../assets/Pictogrammes/picto-retour.svg';
import { Link } from 'react-router-dom';

const BrakingPage = ({data}) => {
    const [brakingData, setBrakingData] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const Arrow = ({ className, style, onClick,type }) => {
        return (
          <img
            src={type === "prev" ?FlechePrecedent: FlecheSuivant}
            className={className}
            style={{ ...style,left: type === "prev" ?10:"unset",right:type === "next" ?10:"unset",zIndex:1 }}
            onClick={onClick}
            alt={type === 'prev' ? 'fleche précédent' : 'fleche suivant'}
          />
        );
      }

      const Div = ({children}) => {
        return <div>{children}</div>
      }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slideToShow: 1,
        slideToScroll: 1,
        nextArrow: <Arrow type="next" />,
        prevArrow: <Arrow type="prev" />,
        centerMode: true,
    }

    useEffect(() => {
        setBrakingData(data.contents);
        setBannerImage(data.bannerImage);
    }, [data]);

    if (!brakingData) {
        return <Loader />;
    }

    return (
        <div className="block flex-col items-center">
            <div className="w-full">
                <div className='bannerContainer' dangerouslySetInnerHTML={{__html:bannerImage}}/>
            </div>
            <div style={{
                padding: "30px 0 10px 20px",
            }}>
                <div className={`flex items-center h-16 `}>
                    <Link to="/">
                        <img src={FlecheRetour} alt="retour" className="h-8 w-4 pl-1" />
                    </Link>
                    <div>
                        <h1 className={`text-4xl font-normal ml-12 font-AudiTypeExtendedNormal`} style={{ lineHeight: "0.1" }}>
                            Freinage
                        </h1>
                    </div>
                </div> 
            </div>
            <div style={{
                width:"90%",
                margin:"auto"
            }}>
                <Slider {...settings}>
                {
                    brakingData.map((braking, index) => {
                        return (
                            <Div key={index} className="">
                                <div className="w-1/3 flex flex-col justify-center gap-y-5">
                                    <h1 className='text-4xl font-bold pl-14 font-AudiTypeExtendedNormal'>{braking.name}</h1>
                                    <p
                                        className="pl-14 font-AudiTypeWideLight"
                                        dangerouslySetInnerHTML={{ __html: braking.description }}
                                        style={{fontSize:14}}
                                    />
                                </div>
                                <div className="w-2/3 max-h-72 flex justify-center items-center">
                                    <video src={`/static/${braking.video}`} loop autoPlay muted className="h-auto" />
                                </div>
                            </Div>
                        )
                    })
                } 
                </Slider>
            </div>
        </div>
    );
};

export default BrakingPage;