import axios from "axios";
import { formatHome } from "../js/utils/formatHome";
import { formatCircuit } from "../js/utils/formatCircuit";
import { formatDataSimple } from "../js/utils/formatDataSimple";
import { formatGamme } from "../js/utils/formatGamme";
import { formatMedias } from "../js/utils/formatMedias";
import { setInLocalStorage } from "../js/utils/setInLocalStorage";
import { setDataCaches } from "../js/utils/setDataInCaches";
import jsonData from "./data.json"

const getDataByLibelle = (libelle,data)=>{
    switch (libelle.toLowerCase()) {
        case "accueil":
            return formatHome(data)
        case "circuit":
            return formatCircuit(data)
        case "freinage":
            return formatDataSimple(data)
        case "trajectoire":
            return formatDataSimple(data)
        case "gamme":
            return formatGamme(data)
        case "medias":
            return formatMedias(data)
        default:
            break;
    }
}

export const getData = async () => {
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_DXP_API_URL}/public/content/category/child/1`)
        const data2 = await axios.post(`${process.env.REACT_APP_DXP_API_URL}/public/content/attributeGroup`)
        const sortedData = Promise.all(data.contentCategory.children.map(async(child,i)=>{
            const contents = data2.data.contents.filter(content=>content.contentCategories[0].id === child.id)
            const obj = {
                identifier: child.libelle,
                id: child.id,
                url: child.libelle === "accueil"?"/":`/${child.libelle}`,
                libelle: contents[0].contentCategories[0].contentCategoryData.find(e=>e.attribute === "category_name").value,
                icone: `${contents[0].contentCategories[0].contentCategoryData.find(e=>e.attribute === "category_image")?.media}`,
                bannerImage: contents[0].contentCategories[0].contentCategoryData.find(e=>e.attribute === "category_description")?.value,
                contents : await getDataByLibelle(child.libelle,contents),
            }
            return obj
        }))
        setInLocalStorage(await sortedData)
        setDataCaches(await sortedData)
        await axios.post(`https://api.oreca.sinfin.dev/generate-json`,{
            data:await sortedData
        },{
            headers: {
                'Content-Type': 'application/json'
            },
        }
        )
        // await fetch("https://api.oreca.sinfin.dev/generate-json",{
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     withCredentials: false,
        //     body: JSON.stringify({data:await sortedData})
        //   });
        return sortedData
    } catch (error) {
        if (jsonData.data) {
            return jsonData.data
        }
        const data =JSON.parse(localStorage.getItem("DATAS")) 
        return data
    }
}