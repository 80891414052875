import axios from "axios";

export const formatMedias = async (data) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_DXP_API_URL}/public/content/media/2`);
        const formatData = data.medias.map((media) => {
            return {
                ...media,
                filePath : `${media.filePath}`
            }
        })
        return formatData
    } catch (error) {
        console.log(error)
    }
}