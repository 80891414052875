export const formatCircuit = (circuits) => {
    const formatData = circuits.map((circuit) => {
        const longueurs = circuit.contentDatas.filter((contentData) => contentData.attributeName.includes('attribute_longueur'))
        const imageLongueurs = circuit.contentDatas.filter((contentData) => contentData.attributeName.includes("attribute_circuitimage"))??[]
        return {
            id: circuit.id,
            name: circuit.contentDatas.find((contentData) => contentData.attributeName === "content_title")?.value,
            image: `${circuit.contentDatas.find((contentData) => contentData.attributeName === "content_image")?.value}`,
            description: circuit.contentDatas.find((contentData) => contentData.attributeName === "content_description")?.value,
            coordinates:[circuit.contentDatas.find((contentData) => contentData.attributeName === "attribute_longitude")?.value,circuit.contentDatas.find((contentData) => contentData.attributeName === "attribute_latitude")?.value],
            imageTop: `${circuit.contentDatas.find((contentData) => contentData.attributeName === "attribute_imagetop")?.value}`,
            longueurs :longueurs.map((longueur) => {
                    const number = Number(longueur.attributeName[longueur.attributeName.length - 1 ])
                    if (!isNaN(number)) {
                        return {
                            ...longueur,
                            image: `${imageLongueurs[number - 2].value}`
                        }
                    }else{
                        return {
                            ...longueur,
                            image: `${circuit.contentDatas.find((contentData) => contentData.attributeName === "content_image")?.value}`
                        }
                    }
                })
        }
    })
    return formatData.sort((a,b) => a.name < b.name?-1:0)
}