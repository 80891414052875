import React from "react";
import { Link } from "react-router-dom";
import AudiDrivingExperience from "../assets/logo-audi-driving-experience.svg";
import AudiLogo from "../assets/logo-audi.svg";

const DrawerLeft = ({data, showPopup, setShowPopup}) => {
  const orderReference = ["accueil", "circuit", "freinage", "trajectoire", "gamme", "medias"]

  const sortedData = data.sort((a,b) => {
    const indexA = orderReference.indexOf(a.identifier)
    const indexB = orderReference.indexOf(b.identifier)
    return indexA - indexB
  });

  return (
    <div className="drawer-container fixed top-0 left-0 h-screen w-64 bg-white shadow-md flex flex-col justify-between">
      <div>
        <div className="h-32 w-full flex bg-red-800 items-center justify-center">
            <Link to="/">
                <img
                src={AudiLogo}
                alt="logo-audi"
                style={{ maxHeight: "160px" }}
                />
            </Link>
        </div>
        <ul className="text-sm mt-8 flex flex-col items-start px-12">
          {sortedData.map((item, index) => {
              return (
                <li key={index} className="p-4 mr-8 mb-4 flex  hover:text-red-800">
                  <img
                    src={`/static/${item.icone}`}
                    alt="Home"
                    className="h-6 w-6 mr-2"
                    style={{ maxHeight: "16px", maxWidth: "16px" }}
                  />
                  <Link to={item.url} className="font-AudiTypeExtendedNormal">{item.libelle}</Link>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="flex justify-center items-end pb-10">
        <img
          src={AudiDrivingExperience}
          style={{width: "153px", height: "32px"}}
          className="mb-4 cursor-pointer	"
          alt="logo-driving-experience"
          onClick={() => setShowPopup(true)}
        />
      </div>
    </div>
  );
};

export default DrawerLeft;