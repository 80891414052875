import React, { useState } from "react";

const SignOutModal = ({ showPopup, setShowPopup }) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const logoutToken = process.env.REACT_APP_LOGOUT_TOKEN;

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setErrorMessage("");
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setErrorMessage("");
    setInputValue("");
  };

  const handleLogout = () => {
    if (inputValue === logoutToken) {
      window.close();
    } else {
      setInputValue("");
      setErrorMessage("Code incorrect, veuillez réessayer");
    }
  };
  return (
    <>
      {showPopup && (
        <div
          style={{ zIndex: 999999 }}
          className="absolute bottom-0 left-0 h-screen w-screen bg-gray-800 bg-opacity-50 flex items-center justify-center"
        >
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <h2 className="text-lg font-semibold mb-4">Vérification</h2>
            <p>Entrez le code de déconnexion:</p>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className="border p-2 my-2"
            />
            <p className="text-red-600">{errorMessage}</p>
            <div className="flex justify-center">
              <br />
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white px-4 py-2 rounded-lg mt-4"
              >
                Déconnexion
              </button>
              <button
                onClick={handlePopupClose}
                className="border px-4 py-2 rounded-lg mt-4 ml-4"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignOutModal;
