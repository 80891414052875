import React from 'react';
import FlecheRetour from '../assets/Pictogrammes/picto-retour.svg';
import { Link } from 'react-router-dom';

const TitleHeader = ({ title, titleStyle, subTitle, subTitleStyle }) => {
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="flex items-center w-screen pl-10 mb-5">
            <Link to="/" onClick={handleGoBack}>
                <img src={FlecheRetour} alt="retour" className="h-8 w-4 pl-1" />
            </Link>
            <div>
                <h2 className={`text-xs font-light ml-5 pl-9 ${titleStyle} font-AudiTypeNormal`} style={{ lineHeight: "0.1", fontSize:14 }}>
                    {title}
                </h2>
                <br />
                <h1 className={`text-4xl ml-5 pl-8 pb-8 ${subTitleStyle} font-AudiTypeExtendedNormal`} style={{ lineHeight: "0.1" }}>
                    {subTitle}
                </h1>
            </div>
        </div>
    );
}

export default TitleHeader;
