import React from "react";
import loader from "../assets/Loader/loader-audi-2.gif";

const Loader = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <img className="w-28 h-28" src={loader} alt="loading..." />
        </div>
    );
}

export default Loader;
