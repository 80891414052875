import axios from "axios"

export const formatGamme = async (data) => {
    try {
        const {data} = await axios.post(`${process.env.REACT_APP_DXP_API_URL}/public/content/attributeGroup`,{
            "attributeGroupIdentifier": "gamme"
        })
        const data2 = await axios.get(`${process.env.REACT_APP_DXP_API_URL}/public/content/category/child/3`)
        const categories = data2.data.contentCategory.children
        const sortCategories = Promise.all(categories.map(async(category, index) => {
            const categoryData = data.contents.find(e => e.contentCategories[0].id === category.id)
            const res = await axios.get(`${process.env.REACT_APP_DXP_API_URL}/public/content/contentCategory/${category.id}`)
            return {
                id: categoryData?.contentCategories[0].id,
                name: categoryData?.contentCategories[0].contentCategoryData.find(e=>e.attribute === 'category_name')?.value,
                image: `${categoryData?.contentCategories[0].contentCategoryData.find(e=>e.attribute === 'category_image')?.media}`,
                contents : res.data.contents.map((content,index) => {
                    return {
                        id: content.id,
                        name: content.contentData.find(e=>e.attributeName === 'content_title')?.value,
                        description: content.contentData.find(e=>e.attributeName === 'content_description')?.value,
                        image: content.contentData.find(e=>e.attributeName === 'content_image')?.media?.filePath,
                        imagePrincipale: content.contentData.find(e=>e.attributeName === 'attribute_imageprincipale')?.media?.filePath,
                        imagePrincipale2: content.contentData.find(e=>e.attributeName === 'attribute_imageprincipale2')?.media?.filePath,
                        imagePrincipale3: content.contentData.find(e=>e.attributeName === 'attribute_imageprincipale3')?.media?.filePath,
                        chevaux: content.contentData.find(e=>e.attributeName === 'attribute_puissance')?.value,
                        kilowatt: content.contentData.find(e=>e.attributeName === 'attribute_kilowatt')?.value,
                        perf: content.contentData.find(e=>e.attributeName === 'attribute_performance')?.value,
                        vmax: content.contentData.find(e=>e.attributeName === 'attribute_vmax')?.value,
                        imageEco: content.contentData.find(e=>e.attributeName === 'attribute_eco')?.media?.filePath,
                    }
                })
            }
        }))
        return await sortCategories
    } catch (error) {
        console.log(error)
    }
}