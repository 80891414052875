import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TitleHeader from "./TitleHeader";
import BgHeader from "./BgHeader";
import { numberWithSpaces } from "../js/utils/NumberWithSpaces";
import Loader from "./Loader";
import { isFirstLetterVowel } from "../js/utils/isFirstLetterVowel";

const CircuitDetail = () => {
    const [circuitData, setCircuitData] = useState(null);
    const [selectedCircuitImage, setSelectedCircuitImage] = useState(null);
    const { state } = useLocation();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        setCircuitData(state.data);
        setSelectedCircuitImage(state.data.image);
    }, [state]);

    if (!circuitData) {
        return <Loader />
    }

    const handleCircuitButtonClick = (image) => {
        setSelectedCircuitImage(image);
    };

    return (
        <>
            <BgHeader image={circuitData.imageTop} />
            <div className="flex justify-center h-screen w-full overflow-hidden gap-x-16">
                <div className="w-1/2 pt-32">
                    <TitleHeader
                        title={isFirstLetterVowel(circuitData.name)? "Circuit d'": "Circuit de"}
                        titleStyle={"text-xs font-light"}
                        subTitle={circuitData.name}
                        subTitleStyle={"text-4xl font-normal"}
                    />
                    <p style={{fontSize:14}} className="pl-28 font-AudiTypeWideLight" dangerouslySetInnerHTML={{ __html: circuitData.description }} />
                    <div className="ml-10">
                            {
                                circuitData.longueurs.length === 1 ?
                                <div className="flex gap-4 mt-4 pl-14">
                                    <div style={{fontSize:14}} className="px-4 py-2 font-AudiTypeBold">Longueur du circuit : {numberWithSpaces(circuitData.longueurs[0].value)} mètres</div>
                                </div>
                                :<div className="ml-3 pl-14 flex gap-x-2 mt-4">
                                    {
                                        circuitData.longueurs.map((longueur, index) => {
                                            return (                                    
                                                <button key={index} className={`border border-gray-300 text-white px-4 py-2 w-28 h-12 font-AudiTypeWideNormal ${selectedCircuitImage === longueur.image ? 'bg-red-800' : 'bg-black'}`} onClick={() => handleCircuitButtonClick(longueur.image)}>{numberWithSpaces(longueur.value)}m</button>
                                            )
                                        })
                                    }
                                </div>
                            }
                    </div>
                </div>
                <div className="w-1/2 pt-32">
                    {selectedCircuitImage && <img src={`/static/${selectedCircuitImage}`} alt="Circuit" className="w-10/12" style={{objectFit:"contain"}} />}
                </div>
            </div>
        </>
    );
};

export default CircuitDetail;
