import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../src/components/HomePage';
import CircuitsPage from '../src/components/CircuitsPage';
import BrakingPage from '../src/components/BrakingPage';
import GammePage from '../src/components/GammePage';
import MediasPage from '../src/components/MediasPage';
import DrawerLeft from '../src/components/DrawerLeft';
import TrajectoryPage from '../src/components/TrajectoryPage';
import CircuitDetail from '../src/components/CircuitDetail';
import GammePageList from './components/GammePageList';
import { getData } from './api/fetch';
import Loader from './components/Loader';
import SignOutModal from './components/SignOutModal';

const App = () => {
  const [data,setData] = useState(null)
  const [showPopup, setShowPopup] = useState(false);

  useEffect(()=>{
    if (!data) {
      getData()
      .then((res)=>{
        setData(res)
      })
      .catch((err)=>console.log(err))
    }
  },[data])

  if (!data) {
    return <Loader/>
  }
  return (
    <Router>
      <div className="">
        <DrawerLeft data={data} showPopup={showPopup} setShowPopup={setShowPopup} />
        <div style={{
          width: "calc(100% - 16rem)",
          marginLeft: "16rem",
        }}>
          <Routes>
            <Route exact path="/" element={<HomePage data={data.find(e=>e.identifier === "accueil")}/>} />
            <Route path="/circuit" element={<CircuitsPage data={data.find(e=>e.identifier === "circuit")} />} />
            <Route path="/freinage" element={<BrakingPage data={data.find(e=>e.identifier === "freinage")}/>} />
            <Route path="/trajectoire" element={<TrajectoryPage data={data.find(e=>e.identifier === "trajectoire")}/>} />
            <Route path="/gamme" element={<GammePage data={data.find(e=>e.identifier === "gamme")} />} />
            <Route path="/gamme/:id" element={<GammePageList data={data.find(e=>e.identifier === "gamme")} />} />
            <Route path="/medias" element={<MediasPage data={data.find(e=>e.identifier === "medias")}/>} />
            <Route path="/circuit/:id" element={<CircuitDetail />} />
          </Routes>
        </div>
        <SignOutModal showPopup={showPopup} setShowPopup={setShowPopup} />
      </div>
    </Router>
  );
};

export default App;