import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import FlecheRetour from '../assets/Pictogrammes/picto-retour.svg';
import FlechePrecedent from '../assets/Pictogrammes/picto-fleche-1.svg';
import FlecheSuivant from '../assets/Pictogrammes/picto-fleche-2.svg';
import MediaPlayer from '../assets/Pictogrammes/picto-media2.svg';

const MediasPage = ({data}) => {
  const [medias, setMedias] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    setMedias(data.contents);
  }, [data]);

  const Div = ({ children }) => {
    return <div className="flex flex-col gap-x-2 pr-5 pt-5 pb-5 font-AudiTypeExtendedNormal">{children}</div>;
  };

  const Arrow = ({ className, style, onClick, type }) => {
    return (
      <img
        src={type === "prev" ? FlechePrecedent : FlecheSuivant}
        className={className}
        onClick={onClick}
        alt={type === 'prev' ? 'fleche précédent' : 'fleche suivant'}
      />
    );
  };

  const settings = {
    className: "carousel-media",
    infinite: false,
    slidesToShow: 3.2,
    rows: 2,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
  };

  const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden',
        maxWidth: '80%',
        maxHeight: '80%',
        padding: '0',
        borderRadius: '0',
        backgroundColor: 'transparent',
        border:"none"
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  };

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedMedia(null);
    setModalIsOpen(false);
  };

  if (!medias) {
    return <Loader />;
  }

  return (
    <div>
      <div style={{
        padding: "70px 0 50px 20px",
      }}>
        <div className="flex items-center h-16 ">
          <Link to="/">
            <img src={FlecheRetour} alt="retour" className="h-8 w-4 pl-1" />
          </Link>
          <div>
            <h1 className={`text-4xl font-normal ml-8 font-AudiTypeExtendedNormal`} style={{ lineHeight: "0.1" }}>
              Médias
            </h1>
          </div>
        </div>
      </div>
      <div style={{
        width: "calc(100% - 144px)",
        margin: "auto"
      }}>
        <div className='h-px bg-black w-96 mb-3' />
        <Slider {...settings}>
          {medias.map((media,i) => (
            <Div key={i}>
              <div
                onClick={() => handleMediaClick(media)}
                style={{ cursor: 'pointer', position: 'relative'}}
              >
                {media.type === "video/mp4" ? (
                <>
                  <video
                    src={`/static/${media.filePath}`}
                    style={{
                        objectFit: "cover",
                        display: "block",
                        height: "200px",
                        width: "100%",
                      }}
                  />
                  <span
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                    }}
                  >
                    <img src={MediaPlayer} alt="mediaplayer svg"/>
                  </span>
                </>
                ) : (
                  <img
                    src={`/static/${media.filePath}`}
                    alt={media.name}
                    style={{
                      objectFit: "cover",
                      display: "block",
                      height: "200px",
                      width: "100%",
                    }}
                  />
                )}
                <p className='pt-1 font-AudiTypeExtendedNormal'>{media.name}</p>
              </div>
            </Div>
          ))}
        </Slider>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={customModalStyles}
        contentLabel="Media Modal"
      >
        {selectedMedia && (
            <>
            <div style={{ width: '100%',backgroundColor:'transparent',display:"flex",justifyContent:"space-between" }}>
                <h2 className="text-xl font-AudiTypeExtendedNormal text-white">{selectedMedia.name}</h2>
                <span onClick={closeModal} style={{cursor:"pointer"}} className='text-white'>x</span>
            </div>
            {selectedMedia.type === "video/mp4" ? (
              <video
                autoPlay
                loop
                controls
                style={{ objectFit: "contain" }}
              >
                <source src={`/static/${selectedMedia.filePath}`} type={selectedMedia.type} />
              </video>
            ) : (
              <img
                src={`/static/${selectedMedia.filePath}`}
                alt={selectedMedia.name}
                style={{ width: '100%', height: '100%' }}
              />
            )}
          {/* <div>
          </div> */}
          </>
        )}
      </Modal>
    </div>
  );
};

export default MediasPage;